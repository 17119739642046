<template>
    <div>
        <!-- Table Container Card -->
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-card no-body>
            <b-card-body>
                <b-row class="mb2_last_child">

                    <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five ">
                        <b-form-select v-model="siteData" @change="filterTable(); getBuilding('filter'); setFilter(); updateDateFormat();">
                            <b-form-select-option value="" disabled>Select Project Site</b-form-select-option>
                            <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five ">
                        <v-select
                            v-model="building"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options="buildings"
                            placeholder="Select Building"
                            @input="getLevels('filter'); filterTable(); setFilter();"
                            :clearable="false"
                        >
                            <template #selected-option="{ name }">
                            <span v-if="name.length < 23">{{name}}</span>
                            <span v-if="name.length >= 23">{{name.substring(0, 23) + '...'}}</span>
                            </template>
                        </v-select>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five ">
                        <v-select
                            v-model="level"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="levels"
                            placeholder="Select Level"
                            @input="filterTable(); setFilter();"
                            :clearable="false"
                        >
                        </v-select>
                    </b-col>
                    
                    <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five ">
                        <b-form-select v-model="location" @change="filterTable(); setFilter();">
                            <b-form-select-option value="" disabled>Select Location</b-form-select-option>
                            <b-form-select-option :value="el._id" v-for="(el, index) in locations" :key="index">{{el.name}}</b-form-select-option>
                        </b-form-select>
                    </b-col>

                </b-row>
            </b-card-body>
        </b-card>

        <b-card no-body class="mb-0">

            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem">
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            @input="filterTable"
                        />
                        <label>entries</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6" class="mobile_tab_max_width_flex all_btn_tab">
                        <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem  "
                                placeholder="Search..."
                                @input="filterTable"
                            />

                            <b-button variant="warning" class="" @click="openModal(null)" v-if="checkPermission($route.name, 'Add Device')">
                                <span class="text-nowrap">Add Device</span>
                            </b-button>

                        </div>
                    </b-col>

                </b-row>
            </div>

            <b-table sticky-header
                responsive
                show-empty
                ref = "refUserListTable"
                class = "position-relative largeTable common_big_tbl"
                primary-key = "id"
                empty-text = "No matching records found"
                :fields = "tableColumns"
                :sort-by.sync = "sortBy"
                :items = "dataList"
                :sort-desc.sync = "isSortDirDesc"
                :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
            >
                <template #cell(info)="items">             
                    <feather-icon
                    :icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
                    @click="items.toggleDetails"
                    />
                </template>
                <template #cell(site)="items">
                    <span class="wordBreak"  @click="items.toggleDetails">
                        <div class="my-1">{{ items.item.site.site_name | capitalize}}</div>
                    </span>
                </template>

                <template #cell(level)="items">
                    <span class="wordBreak"  @click="items.toggleDetails">{{ items.item.level }}</span>
                </template>

                <template #cell(location)="items">
                    <span class="wordBreak"  @click="items.toggleDetails">{{ items.item.location }}</span>
                </template>

                  <template #cell(building_name)="items">
                    <span class="wordBreak"  @click="items.toggleDetails">{{ items.item.building.name }}</span>
                </template>

                 <template #cell(device_id)="items">
                    <span class="wordBreak"  @click="items.toggleDetails">{{ items.item.device_id }}</span>
                </template>

                <template #cell(device_name)="items">
                    <span class="wordBreak"  @click="items.toggleDetails">{{ items.item.device_name | capitalize}}</span>
                </template>

                <!-- <template #cell(min_level_value_in_mm)="items">
                    <span class="wordBreak" v-if="items.item.min_level_value_in_mm && items.item.min_level_value_in_mm != ''">{{ items.item.min_level_value_in_mm }} mm</span>
                    <span class="wordBreak" v-else>N/A</span>
                </template> -->

                <!-- <template #cell(max_level_value_in_mm)="items">
                    <span class="wordBreak" v-if="items.item.max_level_value_in_mm && items.item.max_level_value_in_mm != ''">{{ items.item.max_level_value_in_mm }} mm</span>
                    <span class="wordBreak" v-else>N/A</span>
                </template> -->

                <!-- <template #cell(offline_hours)="items">
                    <span class="wordBreak" v-if="items.item.offline_hours">{{ items.item.offline_hours }} Hour(s)</span>
                    <span class="wordBreak" v-else>N/A</span>
                </template> -->
                
                <template #cell(status)="items">
                    <b-badge pill :variant="variantColor(items.item.status)" class="text-capitalize"  @click="items.toggleDetails">
                        {{ items.item.status}}
                    </b-badge>
                </template>

                <!-- <template #cell(created_at)="items"> -->
                    <!-- <span class="wordBreak"> -->
                        <!-- {{ items.item.created_at | dateRangePicker}} -->
                        <!-- {{sitedateTime2(items.item.created_at,date_format,'')}}
                        <br>
                        {{ sitetimeHM(items.item.created_at,time_format)}}
                    </span> -->
                <!-- </template> -->

                <!-- <template #cell(trigger_value)="items">
                    <span class="wordBreak" v-if="(items.item.trigger_value) && (items.item.device_type == 'Toilet Roll Sensor' || items.item.device_type == 'Hand Soap Dispenser Sensor' || items.item.device_type == 'Trash Bin Sensor' || items.item.device_type == 'Other')">{{ items.item.trigger_value}}%</span>
                    <span class="wordBreak" v-else-if="items.item.trigger_value">{{items.item.trigger_value}}</span>
                    <span class="wordBreak" v-else>{{'N/A'}}</span>
                </template> -->

                <!-- <template #cell(notifications)="items">
                    <span class="wordBreak">{{ items.item.notifications | capitalize }}</span>
                </template> -->


                <!-- <template #cell(percentage)="items"> -->
                    <!-- <span v-if="(items.item.percentage && items.item.percentage >= 0 ) && (items.item.device_type == 'Toilet Roll Sensor' || items.item.device_type == 'Hand Soap Dispenser Sensor' || items.item.device_type == 'Trash Bin Sensor' || items.item.device_type == 'Other')">{{ items.item.percentage + '%'}}</span> -->

                    <!-- <p class="wordBreak" v-if="items.item.percentage && items.item.percentage >= 0">
                        <p class="wordBreak" v-if="[items.item.device_type].includes('Traffic Counter Sensor', 'Ammonia Sensor')">{{items.item.percentage}}</p>
                        <p class="wordBreak" v-else-if="items.item.device_type == 'Wetness Floor Sensor'">{{ items.item.wet | capitalize}}</p>

                        <p class="wordBreak" v-else-if="items.item.device_type == 'Cubicle Occupancy Detection'">{{ items.item.door_status | capitalize}}</p>

                        <p class="wordBreak" v-else-if="[items.item.device_type].includes('Temperature & Humidity Sensor')">T - {{items.item.current_value}} <br> H - {{items.item.current_humidity}}</p>
                        <p class="wordBreak" v-else>{{ Math.round(items.item.percentage) + '%'}}</p>
                    </span>
                    

                    <p class="wordBreak" v-else>{{'N/A'}}</p> -->
                    <!-- <span v-if="items.item.device_type == 'Toilet Roll Sensor' || items.item.device_type == 'Hand Soap Dispenser Sensor' || items.item.device_type == 'Trash Bin Sensor' || items.item.device_type == 'Other'">
                        <span v-if="items.item.percentage < 0 || items.item.percentage > 100"> - </span>
                        <span v-else>{{ items.item.percentage ? Math.round(items.item.percentage) + '%' : '0%'}}</span>
                    </span>
                        
                    <span v-else-if="items.item.device_status == 'Online' && (items.item.device_type == 'Ammonia Sensor' || items.item.device_type == 'Traffic Counter Sensor')">
                        {{ items.item.current_value ? items.item.current_value : '0'}}
                    </span>
                    <span v-else-if="items.item.device_status == 'Offline' && (items.item.device_type == 'Ammonia Sensor' || items.item.device_type == 'Traffic Counter Sensor')">
                        {{ items.item.current_value ? items.item.current_value : '0'}}
                    </span>

                    <span v-else-if="(items.item.device_type == 'Wetness Floor Sensor')">{{ items.item.wet | capitalize}}</span>
                    <span v-else-if="(items.item.device_type == 'Cubicle Occupancy Detection')">{{ items.item.door_status | capitalize}}</span>

                    <span v-else-if="(items.item.device_type == 'Temperature & Humidity Sensor')">T - {{items.item.current_value}} <br> H - {{items.item.current_humidity}}</span>
                    <span v-else>N/A</span> -->
                <!-- </template> -->

                <template #cell(device_status)="items">

                    <span v-if="items.item.device_status == 'Error'"  @click="items.toggleDetails">
                        <b-badge pill variant="info" class="text-capitalize w-100">
                                Error
                        </b-badge>
                    </span>
                    <span v-if="items.item.device_status == 'Empty'"  @click="items.toggleDetails">
                        <b-badge pill :variant="items.item.device_type == 'Trash Bin Sensor' ? 'success' : 'danger'" class="text-capitalize w-100">
                                Empty
                        </b-badge>
                    </span>
                    <span v-if="items.item.device_status == 'Low'"  @click="items.toggleDetails">
                        <b-badge pill variant="warning" class="text-capitalize w-100">
                                Low
                        </b-badge>
                    </span>
                    <span v-if="items.item.device_status == 'Mid'"  @click="items.toggleDetails">
                        <b-badge pill style="background: #D4AF37;" class="text-capitalize w-100">
                                Mid
                        </b-badge>
                    </span>
                    <span v-if="items.item.device_status == 'High'"  @click="items.toggleDetails">
                        <b-badge pill style="background: #e67e26;" class="text-capitalize w-100">
                                High
                        </b-badge>
                    </span>
                    <span v-if="items.item.device_status == 'Full'"  @click="items.toggleDetails">
                        <b-badge pill :variant="items.item.device_type == 'Trash Bin Sensor' ? 'danger' : 'success'" class="text-capitalize w-100">
                                Full
                        </b-badge>
                    </span>
                    <span v-if="items.item.device_status == 'Offline'"  @click="items.toggleDetails">
                        <b-badge pill variant="danger" class="text-capitalize w-100">
                                Offline
                        </b-badge>
                    </span>
                    <span v-if="items.item.device_status == 'Online'"  @click="items.toggleDetails">
                        <b-badge pill variant="success" class="text-capitalize w-100">
                                Online
                        </b-badge>
                    </span>

                </template>

                <template #cell(actions)="items">
                    <!-- <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL"> -->
                        <!-- 3 dot menu -->
                        <!-- <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template> -->

                        <!-- View Overview -->
                        <!-- <b-dropdown-item v-if="items.item.device_type == 'BLE Gateway'" :to="{ name: 'sensor-overview', params: { id: items.item._id, device_name: items.item.device_name, device_type: items.item.device_type, device_id: items.item.device_id } }">
                            <feather-icon icon="BarChart2Icon" />
                            <span class="align-middle ml-50">View Overview</span>
                        </b-dropdown-item> -->

                        <!-- View stats -->
                        <!-- <b-dropdown-item :to="{ name: 'device-stats', params: { id: items.item._id, device_name: items.item.device_name, device_type: items.item.device_type, device_id: items.item.device_id, site: items.item.site, color: graphColor(items.item.percentage, items.item.device_type, items.item.device_status)} }"
                        v-if="items.item.device_type != 'Toilet Roll Sensor' && items.item.device_type != 'Hand Soap Dispenser Sensor' && items.item.device_type != 'Trash Bin Sensor' && items.item.device_type != 'Other'"
                        >
                            <feather-icon icon="BarChart2Icon" />
                            <span class="align-middle ml-50">View Stats</span>
                        </b-dropdown-item>

                        <b-dropdown-item :to="{ name: 'sensor-stats', params: { device_id: items.item._id, sensor_id: items.item.device_id, sensor_name: items.item.device_name, sensor_type: items.item.device_type, color: graphColor(items.item.percentage, items.item.device_type, items.item.device_status)} }"
                        v-if="items.item.device_type == 'Toilet Roll Sensor' || items.item.device_type == 'Hand Soap Dispenser Sensor' || items.item.device_type == 'Trash Bin Sensor' || items.item.device_type == 'Other'"
                        >
                            <feather-icon icon="BarChart2Icon" />
                            <span class="align-middle ml-50">View Stats</span>
                        </b-dropdown-item> -->





                        <!-- View button -->
                        <!-- <b-dropdown-item v-if="items.item.device_type == 'BLE Gateway'" :to="{ name: 'sensor', params: { id: items.item._id } }">
                            <feather-icon icon="CrosshairIcon" />
                            <span class="align-middle ml-50">View Sensor</span>
                        </b-dropdown-item> -->

                        <!-- Edit button -->
                        <!-- <b-dropdown-item @click="openModal(items.item._id)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item> -->

                        <!-- Change Status button -->
                        <!-- <b-dropdown-item @click="statusChange(items.item)">
                            <feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
                            <span class="align-middle ml-50" v-if="items.item.status == 'active'">Inactive</span>
                            <span class="align-middle ml-50" v-if="items.item.status == 'inactive'">Active</span>
                        </b-dropdown-item> -->

                        <!-- Delete button -->
                        <!-- <b-dropdown-item @click="deleteItem(items.item._id)">
                            <feather-icon icon="Trash2Icon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item> -->

                    <!-- </b-dropdown>  -->
                    
                        <b-row>
                            <b-col class="p-0 pb-1 mobile_col" cols="3" v-if="checkPermission($route.name, 'View Status')">
                                <b-link :to="{ name: 'device-stats', params: { id: items.item._id, device_name: items.item.device_name, device_type: items.item.device_type, device_id: items.item.device_id, site: items.item.site, color: graphColor(items.item.percentage, items.item.device_type, items.item.device_status)} }"
                                    v-if="items.item.device_type != 'Toilet Roll Sensor' && items.item.device_type != 'Hand Soap Dispenser Sensor' && items.item.device_type != 'Trash Bin Sensor' && items.item.device_type != 'Other'"
                                        v-b-tooltip.hover.v-warning
                                        title="View Stats"
                                        variant="outline-warning"
                                    >
                                        <feather-icon icon="BarChart2Icon" class="mediumSize mr-1"/>
                                        
                                    </b-link>
                            
                                <b-link :to="{ name: 'sensor-stats', params: { device_id: items.item._id, sensor_id: items.item.device_id, sensor_name: items.item.device_name, sensor_type: items.item.device_type, color: graphColor(items.item.percentage, items.item.device_type, items.item.device_status)} }"
                                    v-if="items.item.device_type == 'Toilet Roll Sensor' || items.item.device_type == 'Hand Soap Dispenser Sensor' || items.item.device_type == 'Trash Bin Sensor' || items.item.device_type == 'Other'"
                                        v-b-tooltip.hover.v-warning
                                        title="View Stats"
                                        variant="outline-warning"
                                    >
                                        <feather-icon icon="BarChart2Icon" class="mediumSize mr-1"/>
                                        
                                    </b-link>
                            
                                <b-link v-if="items.item.device_type == 'BLE Gateway'" :to="{ name: 'sensor', params: { id: items.item._id } }"
                                        v-b-tooltip.hover.v-warning
                                        title="View Sensor"
                                        variant="outline-warning"
                                    >
                                        <feather-icon icon="CrosshairIcon" class="mediumSize mr-1"/>
                                        
                                    </b-link>
                            </b-col>
                            <b-col class="p-0 pb-1 mobile_col" cols="3" v-if="checkPermission($route.name, 'Edit')">
                                <b-link  @click="openModal(items.item._id)"
                                        v-b-tooltip.hover.v-warning
                                        title="Edit"
                                        variant="outline-warning"
                                    >
                                        <feather-icon icon="EditIcon" class="mediumSize mr-1"/>
                                        
                                    </b-link>
                            </b-col>
                            <b-col class="p-0 pb-1 mobile_col" cols="3" v-if="checkPermission($route.name, 'Change Status')">
                                <b-link v-b-tooltip.hover.v-warning :title= "items.item.status == 'active' ? 'Mark Inactive' : 'Mark Active'" variant="outline-warning" @click="statusChange(items.item)">
                                    <feather-icon :icon="items.item.status == 'active' ? 'CheckCircleIcon' : 'CircleIcon'" class="mediumSize mr-1" />	
                                </b-link>
                            </b-col>
                            <b-col class="p-0 mobile_col" cols="3" v-if="checkPermission($route.name, 'Delete')">
                                <b-link @click="deleteItem(items.item._id)"
                                        v-b-tooltip.hover.v-warning
                                        title="Delete"
                                        variant="outline-warning"
                                    >
                                        <feather-icon icon="Trash2Icon" class="mediumSize mr-1"/>
                                        
                                    </b-link>
                            </b-col>
                        </b-row>
                        
                </template>

                 <template #row-details="items">
                    <div class="p-1 px-2 fbDetail mb-1">
                        <b-row>

                        <b-col cols="12" xl="12" >
                            <table class="mt-2 mt-xl-0 w-100">
                                <tr class="mb-1">
                                    <th class="pb-50" style="width: 10%">
                                        <div class="d-flex align-items-center">
                                
                                        <div class="ml-1">
                                            <h6 class="mb-0 mediumFonts">
                                                Alert
                                            </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                        </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                                      <span class="wordBreak">{{ items.item.notifications | capitalize }}</span>
                                    </td>

                                    <th class="pb-50" style="width: 5%">
                                        <div class="d-flex align-items-center">
                                
                                        <div class="ml-1">
                                            <h6 class="mb-0 mediumFonts">
                                                Trigger Value
                                            </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                        </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                                       <span class="wordBreak" v-if="(items.item.trigger_value) && (items.item.device_type == 'Toilet Roll Sensor' || items.item.device_type == 'Hand Soap Dispenser Sensor' || items.item.device_type == 'Trash Bin Sensor' || items.item.device_type == 'Other')">{{ items.item.trigger_value}}%</span>
                                        <span class="wordBreak" v-else-if="items.item.trigger_value">{{items.item.trigger_value}}</span>
                                        <span class="wordBreak" v-else>{{'N/A'}}</span>
                                    </td>

                                    <th class="pb-50" style="width: 10%">
                                        <div class="d-flex align-items-center">
                                
                                        <div class="ml-1">
                                            <h6 class="mb-0 mediumFonts">
                                                Hour(s) For Offline
                                            </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                        </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 5%;padding-top: 8px;">
                                      <span class="wordBreak" v-if="items.item.offline_hours">{{ items.item.offline_hours }} Hour(s)</span>
                                        <span class="wordBreak" v-else>N/A</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="pb-50" style="width: 8%">
                                        <div class="d-flex align-items-center">
                                
                                        <div class="ml-1">
                                            <h6 class="mb-0 mediumFonts">
                                                Min Level
                                            </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                        </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 5%;padding-top: 8px;">
                                        <span class="wordBreak" v-if="items.item.min_level_value_in_mm && items.item.min_level_value_in_mm != ''">{{ items.item.min_level_value_in_mm }} mm</span>
                                        <span class="wordBreak" v-else>N/A</span>
                                    </td>

                                     <th class="pb-50" style="width: 8%">
                                        <div class="d-flex align-items-center">
                                
                                        <div class="ml-1">
                                            <h6 class="mb-0 mediumFonts">
                                                Max Level
                                            </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                        </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 5%;padding-top: 8px;">
                                        <span class="wordBreak" v-if="items.item.max_level_value_in_mm && items.item.max_level_value_in_mm != ''">{{ items.item.max_level_value_in_mm }} mm</span>
                                        <span class="wordBreak" v-else>N/A</span>
                                    </td>

                                     <th class="pb-50" style="width: 8%">
                                        <div class="d-flex align-items-center">
                                
                                        <div class="ml-1">
                                            <h6 class="mb-0 mediumFonts">
                                                 Value
                                            </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                        </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 5%;padding-top: 8px;">
                                        <span v-if="items.item.device_type == 'Toilet Roll Sensor' || items.item.device_type == 'Hand Soap Dispenser Sensor' || items.item.device_type == 'Trash Bin Sensor' || items.item.device_type == 'Other'">
                                            <span v-if="items.item.percentage < 0 || items.item.percentage > 100"> - </span>
                                            <span v-else>{{ items.item.percentage ? Math.round(items.item.percentage) + '%' : '0%'}}</span>
                                        </span>
                                            
                                        <span v-else-if="items.item.device_status == 'Online' && (items.item.device_type == 'Ammonia Sensor' || items.item.device_type == 'Traffic Counter Sensor')">
                                            {{ items.item.current_value ? items.item.current_value : '0'}}
                                        </span>
                                        <span v-else-if="items.item.device_status == 'Offline' && (items.item.device_type == 'Ammonia Sensor' || items.item.device_type == 'Traffic Counter Sensor')">
                                            {{ items.item.current_value ? items.item.current_value : '0'}}
                                        </span>

                                        <span v-else-if="(items.item.device_type == 'Wetness Floor Sensor')">{{ items.item.wet | capitalize}}</span>
                                        <span v-else-if="(items.item.device_type == 'Cubicle Occupancy Detection')">{{ items.item.door_status | capitalize}}</span>

                                        <span v-else-if="(items.item.device_type == 'Temperature & Humidity Sensor')">T - {{items.item.current_value}} <br> H - {{items.item.current_humidity}}</span>
                                        <span v-else>N/A</span>
                                    </td>

                                </tr>

                                <tr>

                                     <th class="pb-50" style="width: 8%">
                                        <div class="d-flex align-items-center">
                                
                                        <div class="ml-1">
                                            <h6 class="mb-0 mediumFonts">
                                                 Added On
                                            </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                        </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 5%;padding-top: 8px;">
                                          <span class="wordBreak">
                                            <!-- {{ items.item.created_at | dateRangePicker}} -->
                                            {{sitedateTime2(items.item.created_at,date_format,'')}}
                                            <br>
                                            {{ sitetimeHM(items.item.created_at,time_format)}}
                                        </span>
                                    </td>

                                       <th class="pb-50" style="width: 8%">
                                        <div class="d-flex align-items-center">
                                
                                        <div class="ml-1">
                                            <h6 class="mb-0 mediumFonts">
                                                 Device Type
                                            </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                        </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 5%;padding-top: 8px;">
                                          <span class="wordBreak">
                                         {{items.item.device_type}}
                                        </span>
                                    </td>

                                </tr>
                                <tr class="mb-1">
                                    <th class="pb-50" style="width: 16%">
                                    <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-secondary"
                                        @click="items.toggleDetails"
                                        size="sm"
                                        class="ml-1"
                                        >
                                        Hide Detail
                                        </b-button>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                </tr>
                            </table>
                        </b-col>

                        </b-row>
                    </div>
                 </template>

            </b-table>

            <div class="mx-2 mb-2">
                <b-row>

                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
                    </b-col>

                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecords"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        @input="filterTable"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                        </b-pagination>
                    </b-col>

                </b-row>
            </div>

        </b-card>
        
        <!-- Add Device Modal -->
        <b-modal 
            id="add-device" centered ref="add-device" cancel-variant="outline-secondary"
            ok-variant="outline-warning"
            ok-title="Submit"
            cancel-title="Close"
            :title="title"
            @show="resetForm"
            @hidden="resetForm"
            @ok="addDevice"
            no-close-on-backdrop
            size="lg"
        >
            <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                <div class="alert-body"> {{error_message}} </div>
            </b-alert>

            <b-form>
                <b-row>
                    <b-col md="6">
                        <b-form-group label="Project Site" class="required">
                            <b-form-select v-model="form.site" @change="getBuildingList('form')">
                                <b-form-select-option value="" disabled>Select</b-form-select-option>
                                <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id" v-if="site._id != 'all-site'">{{site.site_name | capitalize}}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    
                    <b-col md="6">
                        <b-form-group label="Building" class="required">              
                            <v-select
                                v-model="form.building"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="name"
                                :options="building_list"
                                placeholder="Select"
                                @input="getLevelList('form')"
                                :clearable="false"
                            >
                                <template #selected-option="{ name }">
                                <span v-if="name.length < 23">{{name}}</span>
                                <span v-if="name.length >= 23">{{name.substring(0, 23) + '...'}}</span>
                                </template>
                            </v-select>
                        </b-form-group>
                    </b-col>
                    
                    <b-col md="6">
                        <b-form-group label="Level" class="required">   
                            <v-select
                                v-model="form.level"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="level_list"
                                placeholder="Select"
                                :clearable="false"
                            >
                            </v-select>
                        </b-form-group>
                    </b-col>
                    
                    <b-col md="6">
                        <b-form-group label="Location" class="required">
                            <b-form-select v-model="form.location">
                                <b-form-select-option value="" disabled>Select</b-form-select-option>
                                <b-form-select-option :value="el._id" v-for="(el, index) in locations" :key="index">{{el.name}}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    
                    <b-col md="6">
                        <b-form-group label="Device Type" class="required">
                            <b-form-select v-model="form.device_type" @change="resetDeviceTypeData">
                                <b-form-select-option value="" disabled>Select</b-form-select-option>
                                <b-form-select-option :value="type" v-for="(type, index) in device_types" :key="index">{{type}}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    
                    <b-col md="6">
                        <b-form-group label="Device Id" class="required">
                            <b-form-input placeholder="Enter Device Id" v-model="form.device_id" autocomplete="off" />
                        </b-form-group>
                    </b-col>
                    
                    <b-col md="6">
                        <b-form-group label="Device Name" class="required">
                            <b-form-input placeholder="Enter Device Name" v-model="form.device_name" autocomplete="off"/>
                        </b-form-group>
                    </b-col>
                    
                    <b-col md="6" v-if="form.device_type == 'Toilet Roll Sensor' || form.device_type == 'Hand Soap Dispenser Sensor' || form.device_type == 'Trash Bin Sensor' || form.device_type == 'Other'">
                        <b-form-group label="Minimum Level (in mm)" class="required">
                            <b-form-input placeholder="Enter Minimum Level" v-model="form.min_level_value_in_mm" autocomplete="off"/>
                        </b-form-group>
                    </b-col>
                    
                    <b-col md="6" v-if="form.device_type == 'Toilet Roll Sensor' || form.device_type == 'Hand Soap Dispenser Sensor' || form.device_type == 'Trash Bin Sensor' || form.device_type == 'Other'">                    
                        <b-form-group label="Maximum Level (in mm)" class="required">
                            <b-form-input placeholder="Enter Maximum Level" v-model="form.max_level_value_in_mm" autocomplete="off"/>
                        </b-form-group>
                    </b-col>
                    
                    

                    <!-- <b-col md="6" v-if="form.device_type == 'Traffic Counter Sensor' || form.device_type == 'Ammonia Sensor'">                     -->
                    <b-col md="6">                    
                        <b-form-group label="Hour(s) for Offline" class="required">
                            <b-form-input placeholder="Enter Hour(s) for Offline" v-model="form.offline_hours" @keypress="isNumber($event, form.offline_hours)" autocomplete="off"/>
                        </b-form-group>
                    </b-col>
                    

                </b-row> 

                <hr>     
                <b-row>
                    <b-col md="6" v-if="form.device_type != 'Wetness Floor Sensor'">
                        <b-form-group label="">
                            <b-form-checkbox v-model="form.notifications" value="yes" unchecked-value="no" class="custom-control-warning mt-2" @change="resetTriggerValue()">
                                Enable Email Notification
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>

                    <b-col md="6" v-if="form.device_type != 'Wetness Floor Sensor'">
                        <b-form-group label="">
                            <b-form-checkbox v-model="form.push_notifications" value="yes" unchecked-value="no" class="custom-control-warning mt-2" @change="resetTriggerValue()">
                                Enable Push Notification
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-if="(form.notifications == 'yes' || form.push_notifications == 'yes')">
                    <b-col md="12" v-if="form.device_type != 'BLE Gateway' && form.device_type != 'Wetness Floor Sensor'">
                        <b-form-group :label="form.device_type == 'Toilet Roll Sensor' || form.device_type == 'Hand Soap Dispenser Sensor' || form.device_type == 'Trash Bin Sensor' || form.device_type == 'Other' ? 'Trigger Value (in Percentage)' : form.device_type == 'Cubicle Occupancy Detection' ? 'Trigger Value (in minutes)' : 'Trigger Value'" :class="(form.notifications == 'yes' || form.push_notifications == 'yes') ? 'required' : ''">
                            <b-form-input placeholder="Enter Trigger Value" v-model="form.trigger_value" autocomplete="off"/>
                        </b-form-group>
                    </b-col>

                </b-row>
            </b-form>

        </b-modal>
    
    </div>
</template>

<script>
    import {
        BFormSelect,VBTooltip, BFormSelectOption, BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BAlert, BFormText, BFormGroup, BForm, BBreadcrumb
    } from 'bootstrap-vue'
    import { POST_API } from "../../../store/actions.type"
    import vSelect from 'vue-select';

    export default {
        components: {
            vSelect, BFormSelect, BFormSelectOption, BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormCheckbox, BAlert, BFormText, BFormGroup, BForm, BBreadcrumb
        },
         directives: {
            'b-tooltip': VBTooltip
        },
        data() {
            return {
                date_format:'DD MMM YYYY',
                time_format:'HH:mm',
                webUrl: process.env.VUE_APP_SERVER_URL,
                error_message: null, 
                showDismissibleAlert:false,

                sites: null,
                // filter vars 
                siteData: '',
                building: '',
                level: '',
                location: '',
                
                // vars for filter fetch
                filter: {},
                prev_route: null,

                device_types : ['Hand Soap Dispenser Sensor', 'Toilet Roll Sensor', 'Trash Bin Sensor', 'Traffic Counter Sensor', 'Ammonia Sensor','Paper Hand Towel Sensor', 'Temperature & Humidity Sensor','Wetness Floor Sensor','Cubicle Occupancy Detection', 'Other'],

                locations : null,

                tableColumns: [
                    { key: 'info', label: 'INFO', sortable: false, thStyle: { width: '1%' } },
                    { key: 'device_id', label: 'Device Id', sortable: true , thStyle:  {width: '5%'}},
                    // { key: 'device_type', label: 'Device Type', sortable: true , thStyle:  {width: '7%'}},
                    { key: 'device_name', label: 'Device Name', sortable: true , thStyle:  {width: '12%'}},
                    { key: 'site', label: 'Project Site Name', sortable: false , thStyle:  {width: '12%'}},
                    { key: 'building_name', label: 'Building', sortable: true , thStyle:  {width: '10%'}},
                    { key: 'level', label: 'Level', sortable: true , thStyle:  {width: '6%'}},
                    { key: 'location.name', label: 'Location', sortable: true , thStyle:  {width: '6%'}},
                    { key: 'device_status', label: 'Device Status', sortable: false , thStyle:  {width: '5%'}},
                    // { key: 'percentage', label: 'Value', sortable: false , thStyle:  {width: '6%'}},
                    // { key: 'notifications', label: 'Alert', sortable: true , thStyle:  {width: '5%'}},
                    // { key: 'trigger_value', label: 'Trigger Value', sortable: true , thStyle:  {width: '6%'}},
                    // { key: 'offline_hours', label: 'Hour(s) for Offline', sortable: true , thStyle:  {width: '6%'}},
                    // { key: 'min_level_value_in_mm', label: 'Min Level', sortable: true , thStyle:  {width: '5%'}},
                    // { key: 'max_level_value_in_mm', label: 'Max Level', sortable: true , thStyle:  {width: '5%'}},
                    // { key: 'status', label: 'Status', sortable: false , thStyle:  {width: '5%'}},
                    // { key: 'created_at', label: 'Added On', sortable: false , thStyle:  {width: '5%'}},
                    { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '15%'}},
                ],  

                // b-table and pagination options
                    items           : [],
                    isSortDirDesc   : true,
                    perPageOptions  : [10, 20, 50, 100],
                    perPage         : 10,
                    sortBy          : 'id',
                    totalRecords    : 0,
                    currentPage     : 1,
                    searchQuery     : '',
                    from            : null,
                    to              : null,

                title: null,

                buildings: [],
                levels: [],
                
                // Model Form Vars 
                building_list : [],
                level_list    : [],

                // Device vars
                    form : {
                        id          : '',
                        site        : '',
                        device_name : '',
                        device_type : '',
                        device_id   : '',
                        building    : '',
                        level       : '',
                        location : '',
                        notifications : 'yes',
                        push_notifications:'yes',
                        trigger_value : null,
                        offline_hours : null,
                        max_level_value_in_mm:'',
                        min_level_value_in_mm:''
                    }
            }
        },

        methods : {
            isNumber($event, data) {
                // let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                // // only allow number 
                // if (keyCode < 48 || keyCode > 57) {
                //     $event.preventDefault();
                // }
            },

            graphColor(percentage, device_type, status){
                // console.log(percentage, device_type, status);
                if(percentage && percentage != 'N/A'){
                    if(percentage >= 0 && percentage <= 10){
                        return device_type == 'Trash Bin Sensor' ? '28c76f' : 'ea5455';
                    }
                    if(percentage > 10 && percentage <= 30){
                        return 'ff9f43';
                    }
                    if(percentage > 30 && percentage <= 60){
                        return 'D4AF37';
                    }
                    if(percentage > 60 && percentage <= 85){
                        return 'e67e26';
                    }
                    if(percentage > 85){
                        return device_type == 'Trash Bin Sensor' ? 'ea5455' : '28c76f';
                    }
                }
                else if(status == 'Offline'){
                    return 'ea5455';
                } 
                else if(status == 'Online'){
                    return '28c76f';
                }
            },

            dataList(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        page          : this.currentPage,
                        keyword       : this.searchQuery,
                        rowsPerPage   : this.perPage,
                        sortBy        : this.sortBy,
                        sortDirection : this.isSortDirDesc,
                        role          : this.$store.getters.currentUser.role,
                        om_sites      : this.$store.getters.currentUser.om_sites,
                        site          : this.siteData != '' ? this.siteData :null,
                        building      : this.building,
                        level         : this.level,
                        location   : this.location,
                    },
                    api: '/api/device-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items          = this.$store.getters.getResults.data.docs;
                        this.totalRecords   = this.$store.getters.getResults.data.total;
                        this.perPage        = this.$store.getters.getResults.data.limit;
                        this.from           = this.$store.getters.getResults.data.from
                        this.to             = this.$store.getters.getResults.data.to

                        return this.items;
                    }
                });
            },

            filterTable(){
                this.$refs.refUserListTable.refresh();
            },

            variantColor(status){
                if (status == 'active') {
                    return 'success';
                }else{
                    return 'primary';
                }
            },

            resetForm(){
                this.form.id          = '';
                this.form.site        = '';
                this.form.device_name = '';
                this.form.device_type = '';
                this.form.device_id   = '';
                this.form.building    = '';
                this.form.level       = '';
                this.form.location = '',
                this.form.notifications = 'yes';
                this.form.push_notifications = 'yes';
                this.form.trigger_value = null;
                this.form.offline_hours = null;
                this.form.min_level_value_in_mm = '';
                this.form.max_level_value_in_mm = '';

                this.building_list = [];
                this.level_list    = [];
            },

            openModal(id){
                this.showDismissibleAlert = false;
                this.title = id ? 'Edit Device' : 'Add Device';

                if(id){
                    this.getDeviceDetails(id);
                }

                this.$refs['add-device'].show();
            },

            getDeviceDetails(id){
                return this.$store.dispatch(POST_API, {
                    data:{
                        id : id
                    },
                    api:"/api/device-detail",
                    })
                    .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                    } else {
                        var data = this.$store.getters.getResults.data;

                        this.form.id          = data._id;
                        this.form.site        = data.site._id;
                        this.form.device_name = data.device_name;
                        this.form.device_type = data.device_type;
                        this.form.device_id   = data.device_id;
                        this.form.location = data.location;
                        this.form.notifications = data.notifications;
                        this.form.push_notifications = data.push_notifications;
                        this.form.trigger_value = data.trigger_value;
                        this.form.offline_hours = data.offline_hours;
                        this.form.min_level_value_in_mm = data.min_level_value_in_mm;
                        this.form.max_level_value_in_mm = data.max_level_value_in_mm;
                        this.building_list = data.building_list;
                        // this.level_list = [];
                        this.level_list = data.levels;
                        // console.log(this.level_list)
                        this.form.building = data.building;

                        this.form.level = data.level;  
                    }
                });
            },

            addDevice(e){
                e.preventDefault();
                return this.$store.dispatch(POST_API, {
                    data:{
                        items : this.form
                    },
                    api:this.form.id ? "/api/edit-device" : "/api/add-device",
                    })
                    .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                    } else {
                        this.$refs['add-device'].hide();
                        this.successAlert();
                        this.filterTable();
                    }
                });
            },

            allSites(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        role:this.$store.getters.currentUser.role,
                        om_sites:this.$store.getters.currentUser.om_sites,
                    },
                    api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.sites = this.$store.getters.getResults.data;
                        // if (this.sites.length == 1 ) {
                        //     this.siteData = this.sites[0]._id;

                        //     this.getBuilding('filter'); 
                        //     this.setFilter();
                        //     this.updateDateFormat();

                        //     this.filterTable();
                        // }
                        var obj = {
                            _id:'all-site',
                            site_name:'All Project Sites',
                            pdf_date_format: 'DD MMM YYYY',
                            pdf_time_format: 'HH:mm'
                        }
                        this.sites.unshift(obj);

                        this.siteData = this.defaultSite(this.sites)._id;
                        if(this.siteData && this.siteData._id != 'all-site'){
                            this.getBuilding('filter'); 
                        }
                        this.setFilter();
                        this.updateDateFormat();
                        this.filterTable();
                        
                        return this.sites;
                    }
                });
            },

            allLocations(){
                return this.$store.dispatch(POST_API, {
                    data:{},
                    api: '/api/all-database-location'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.locations = this.$store.getters.getResults.data;
                        return this.locations;
                    }
                });
            },

            statusChange(item){
                if (item.status == 'active') {
                    var msg     = 'Are you sure want to inactivate this record?';
                } else{
                    var msg     = 'Are you sure want to activate this record?';
                }

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id     : item._id,
                                status : item.status
                            },
                            api : "/api/change-device-status",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                this.showAlert();
                            } else {
                                this.successAlert();
                                this.$refs.refUserListTable.refresh();
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },

            deleteItem(id){
                var msg = 'Are you sure want to delete this record?';

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id     : id,
                                status : 'deleted'
                            },
                            api : "/api/change-device-status",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;

                                this.showAlert();
                            } else {
                                this.successAlert();

                                this.$refs.refUserListTable.refresh();
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },

            getBuilding(type){
                this.buildings = [];
                this.form.building = '';
                this.levels = [];
                this.form.level = '';
                this.building = '';
                this.level = '';
                var site = type == 'form' ? this.form.site ? this.form.site : null : this.siteData;

                if (this.form.site != null) {
                    return this.$store.dispatch(POST_API, {
                        data:{
                            role: this.$store.getters.currentUser.role,
                            site: site
                        },
                        api: '/api/all-buildings'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                        } else {
                            this.buildings    = this.$store.getters.getResults.data;
                            return this.buildings;
                        }
                    });
                }
            },
            
            getBuildingList(type){
                this.building_list = [];
                this.form.building = '';
                this.level_list = [];
                this.form.level = '';
                var site = type == 'form' ? this.form.site ? this.form.site : null : this.siteData;

                if (this.form.site != null) {
                    return this.$store.dispatch(POST_API, {
                        data:{
                            role: this.$store.getters.currentUser.role,
                            site: site
                        },
                        api: '/api/all-buildings'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                        } else {
                            this.building_list    = this.$store.getters.getResults.data;
                            return this.building_list;
                        }
                    });
                }
            },

            getLevels(type){
                this.levels = [];
                this.form.level = '';
                this.level = '';

                if(type == 'form'){
                    if (this.form.building.basement == 'yes') {
                        for (var i = 1; i <= this.form.building.no_of_basements; i++) {
                            this.levels.push('Basement '+i);
                        }
                    }
    
                    for (var j = 1; j <= this.form.building.no_of_levels; j++) {
                        this.levels.push('Level '+j);
                    }
                } else {
                    if (this.building.basement == 'yes') {
                        for (var i = 1; i <= this.building.no_of_basements; i++) {
                            this.levels.push('Basement '+i);
                        }
                    }

                    for (var j = 1; j <= this.building.no_of_levels; j++) {
                        this.levels.push('Level '+j);
                    }
                }

                return this.levels;
            },
            
            getLevelList(type){
                this.level_list = [];
                this.form.level = '';

                if(type == 'form'){
                    if (this.form.building.basement == 'yes') {
                        for (var i = 1; i <= this.form.building.no_of_basements; i++) {
                            this.level_list.push('Basement '+i);
                        }
                    }
    
                    for (var j = 1; j <= this.form.building.no_of_levels; j++) {
                        this.level_list.push('Level '+j);
                    }
                } else {
                    if (this.building.basement == 'yes') {
                        for (var i = 1; i <= this.building.no_of_basements; i++) {
                            this.level_list.push('Basement '+i);
                        }
                    }

                    for (var j = 1; j <= this.building.no_of_levels; j++) {
                        this.level_list.push('Level '+j);
                    }
                }

                return this.level_list;
            },

            setFilter(){
                // save filters in local store 
                if(this.siteData != ''){
                    this.filter['site'] = this.siteData;
                }
                if(this.building != ''){
                    this.filter['building'] = this.building;
                }
                if(this.level != ''){
                    this.filter['level'] = this.level;
                }
                if(this.location != ''){
                    this.filter['location'] = this.location;
                }

                localStorage.setItem("filter", JSON.stringify(this.filter));
            },
            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
              },{
                to:null,
                text: 'Smart Sensors',
              },{
                to:null,
                text:'Devices',
                active:true
              }];
              return item;
            },
            updateDateFormat(){

                if (this.siteData != '') {
                    this.sites.forEach(item => {
                        
                        if (item._id == this.siteData) {
                            this.date_format = item.pdf_date_format;
                            this.time_format = item.pdf_time_format;
                            
                        }
                    })

                }else{
                    this.date_format = 'DD MMM YYYY';
                    this.time_format = 'HH:mm';
                }
            },
            resetDeviceTypeData(e){
                if (e == 'Wetness Floor Sensor') {
                    this.form.notifications = 'no'; 
                    this.form.push_notifications = 'no'; 
                }else{
                    this.form.notifications = 'yes'; 
                    this.form.push_notifications = 'yes';
                }

                this.form.min_level_value_in_mm = ''; 
                this.form.max_level_value_in_mm = ''; 
                this.form.trigger_value = '';
            },
            resetTriggerValue(){
                console.log(this.form.notifications, this.form.push_notifications)
                if(this.form.notifications == 'no' && this.form.push_notifications == 'no'){
                    this.form.trigger_value = '';
                }
            }

        },
        mounted(){
            this.allSites().then(() => {
                this.allLocations();
            });

            if(this.prev_route && (this.prev_route.name == 'device-stats' || this.prev_route.name == 'sensor-stats')){
                var filter = JSON.parse(localStorage.getItem("filter"));

                if(filter){
                    this.siteData = filter.site ? filter.site : '';
                    this.getBuilding('filter').then(() => {
                        this.building = filter.building ? filter.building : '';
                        this.getLevels('filter');
                        this.level = filter.level ? filter.level : '';
                    })
                    this.location = filter.location ? filter.location : '';
                }
            } else {
                localStorage.setItem("filter", JSON.stringify({}));
            }

        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.prev_route = from;
            });
        },
    }
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
